import { graphql } from 'gatsby';
import React from 'react';
import { getImage } from '../../../../utils/sanityTypes';


import TitleStepByStep from '../../StepByStep';
import { StepByStepSectionProps } from './types';
import { StepItemProps } from '../../../molecules/Step/types';

const StepByStepSection: React.VFC<StepByStepSectionProps> = ({ title, steps }) => {
  return (
    <TitleStepByStep
      data={{
        title: title ?? '',
        items: (steps ?? []).map<StepItemProps>((singleStep) => {
          const { _key, title: heading, description: content, image: image } = singleStep ?? {};

          return {
            key: _key ?? '',
            title: heading ?? '',
            description: content ?? [],
            image: getImage(image),
          };
        }),
      }}
    />
  );
};

export const fragment = graphql`
  fragment StepByStepSectionFragment on SanityStepByStepSection {
    _type
    _key
    title
    steps {
      _type
      _key
      title
      description: _rawDescription(resolveReferences: { maxDepth: 10 })
      image {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default StepByStepSection;