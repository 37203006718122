import React from 'react';

import RichText from '../RichText';
import * as Styled from './styles';
import { StepProps } from './types';
import Image from '../../atoms/Image';


const Step: React.FC<StepProps> = ({ items }) => {
  
  return (
    <Styled.StepsWrapper aria-label="step">
      {items.map(({ key, title, description, image}) => (
        <Styled.Step key={`${key}-head`}>
          <Styled.StepHead>
            <Styled.StepTitle>
              {title}
            </Styled.StepTitle>
          </Styled.StepHead>
            <Styled.StepContent>
              <RichText data={description} />
              <Image {...image}/>
            </Styled.StepContent>    
        </Styled.Step>
      ))}
      
    </Styled.StepsWrapper>
    
  );
};

export default Step;
