import styled from 'styled-components';

import { Box, Inner } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';

export const TitleStepByStepInner = styled(Inner)`
  display: flex;
  width: 100%;

  @media ${media.maxPhone} {
    flex-direction: column;
  }

  @media ${media.phone} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TitleStepByStepHeading = styled(Box)`
  width: 100%;
  padding-right: 3rem;
  max-width: 19.25rem;
`;

export const TitleStepByStepStep = styled(Box)`
  width: 100%;
  max-width: 42.5rem;

  @media ${media.maxPhone} {
    margin-top: 5rem;
  }
`;
