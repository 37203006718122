import styled from 'styled-components';

import { SingleStepStyledProps } from './types';

import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';
import { Box } from '../../styles/Grid/styles';

export const StepsWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.additional.dark};
`;

export const StepHead = styled(Box)`
  cursor: pointer;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid ${({ theme }) => theme.colors.additional.dark};
`;

export const StepTitle = styled(Typography).attrs({
  forwardedAs: 'h2',
})`
  padding-right: 4rem;
  ${({ theme }) => theme.headingS};

  @media ${media.tablet} {
    ${({ theme }) => theme.headingM};
  }
`;

export const StepContent = styled(Box)`
  padding-bottom: 3rem;
`;

export const Step = styled(Box)<SingleStepStyledProps>`
  max-height: none;
`;
