import React from 'react';

import * as Styled from './styles';
import Heading from '../../molecules/Heading';
import Step from '../../molecules/Step';
import { TitleStepDataProps } from './types';

const TitleStepByStep: React.FC<TitleStepDataProps> = ({ data }) => {
  const { title, items } = data;

  return (
    <Styled.TitleStepByStepInner>
      <Styled.TitleStepByStepHeading>
        <Heading {...{ title }} />
      </Styled.TitleStepByStepHeading>
      <Styled.TitleStepByStepStep>
        <Step {...{ items }} />
      </Styled.TitleStepByStepStep>
    </Styled.TitleStepByStepInner>
  );
};

export default TitleStepByStep;
